<template>
  <div>
    <van-row justify="center" class="chosen">
      <van-col
        span="12"
        v-for="item in goods"
        :key="item.id"
        class="r_item"
        @click="goto(item.id)"
      >
        <div class="img-div"><img :src="item.image" /></div>
        <div class="title tl">{{ item.title }}</div>
      </van-col>
    </van-row>

    <siteabbar :idx="1" />
  </div>
</template>

<script>
import Siteabbar from "../components/Sitetabbar";
import { reactive, onMounted, toRefs } from "vue";
import { getSearchGoods } from "@/api/query";
import { useRoute } from "vue-router";
import { Row, Col, Image } from "vant";

export default {
  components: {
    [Siteabbar.name]: Siteabbar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
  },
  setup() {
    const state = reactive({
      goods: [],
    });
    const route = useRoute();
    onMounted(async () => {
      const { k } = route.query;
      const { data } = await getSearchGoods(k);
      state.goods = data;
    });
    return {
      ...toRefs(state),
    };
  },
  data() {
    return {};
  },
  created() {
    // Mock: get all routes and roles list from server
  },

  methods: {
    goto(id) {
      // this.$router.push("/bar-clist?catid=" + id);
      this.$router.push("/goods?id=" + id);
    },
  },
};
</script>

<style lang="less" scoped>
.top .r_item,
.chosen .r_item {
  padding: 0 10px;
}
.img-div {
  overflow: hidden;
}
.chosen .img-div {
  height: auto;
}
.img-div img {
  width: 100%;
  height: auto;
}
</style>
